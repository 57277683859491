import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Behandlung auswählen',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "Services" */ '../views/ServicesView/ServicesView.vue');
    }},
    {
      path: '/time-slots',
      name: 'Datum & Uhrzeit',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: function () {
        return import(/* webpackChunkName: "Time Slots" */ '../views/TimeSlotsView/TimeSlotsView.vue');
      }
    },
    {
      path: '/contact-info',
      name: 'Kontaktangaben',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: function () {
        return import(/* webpackChunkName: "Contact Info" */ '../views/ContactInfoView/ContactInfoView.vue');
      }
    },
    {
      path: '/confirmation',
      name: 'Confirmation',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: function () {
        return import(/* webpackChunkName: "Confermation" */ '../views/ConfirmationView/ConfirmationView.vue');
      }
    },
    {
      path: '/cancel-confirmation/:id',
      name: 'Cancel Confirmation',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: function () {
        return import(/* webpackChunkName: "Confermation" */ '../views/CancelConfirmationView/CancelConfirmationView.vue');
      }
    },
    {
      path: '/form',
      name: 'Form',
      component: function () {
        return import(/* webpackChunkName: "Confermation" */'../views/JotFormConfirmationView/JotFormConfirmationView.vue');
      }
    }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
